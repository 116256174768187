@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bold';
  src: local('Bold'), url("./assets/fonts/Bold.otf") format('truetype');  
}
@font-face {
  font-family: 'Regular';
  src: local('Regular'), url("./assets/fonts/Regular.otf") format('truetype');  
}
@font-face {
  font-family: 'Light';
  src: local('Light'), url("./assets/fonts/Light.otf") format('truetype');  
}

.section {
  width: 100%;
  height: 100vh;
}

/* .test{ */

  /* backdrop-filter: blur(4px); */
  /* filter: blur(4px); */
  /* background-image: linear-gradient(to right top, #0a0a0a, #0c0c0c, #0d0d0d, #0f0f0f, #101010, #19161f, #251a2d, #341c38, #5d1746, #8b0044, #b20030, #cc0000); */
/* } */
.bold {
  font-family: Bold ;
}
.regular {
  font-family: Regular ;
}
.light {
  font-family: Light ;
}


 

.background {
  background-image: linear-gradient(to left bottom, #cc0000, #9d001b, #6a071f, #380e18, #000000);
}

/* .back {
  background-image: url('./assets/bg-1.png');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
} */

.back2 {
  background-image: url('./assets/back2.svg');
  background-size: cover;
  /* background-position: fixed; */
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.leading-loose {
  line-height: 2em;
}
.container {
  position: relative;
  height: 100vh;
  width: 100vw;
}


 

  .img3{
    height: 60vh;
    background-image: url('./assets/group2.svg');
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    }
 
.filter {
  backdrop-filter: 2rem;
  filter: blur(1rem);
}
  
 
  


.navright{
  position:absolute;
  top:calc(40% - 100px);
  right:20px;
}

.fixed{
  position: fixed;
  z-index:70;
}



@media only screen and (max-width: 767px){
  .flexbox {
   display: none;
  }
  .navright{
   position:absolute;
   display: none;
   bottom: 0;
   left:calc(43% - 100px);
   }

   #resize{
    height: 80vh;
   }

   .footer {
    height: 80vh;
  }
  
 }

 
  
  @media(max-width: 800px){
    #resize{
      height: 80vh;
     }
  }

   
   @media only screen and (max-width: 1024px) {
     #reduce {
       display: none;
     }

     #resize{
      height: 50vh;
     }
     .footer {
      height: 50vh;
    }
   }